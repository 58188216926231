.footer {
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
  padding: 20px;
  background-color: #343a40;
  height: 120px;
  color: white;
}

.creditsText {
  font-size: 16px;
  letter-spacing: 1px;
  animation: fadeIn 2s ease-in-out;
}

.repoLinks {
  margin-top: 10px;
  font-size: 14px;
  animation: fadeIn 3s ease-in-out;
}

.repoLink {
  color: #00d1b2;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.repoLink:hover {
  color: #4dc0b5;
}

.creditLink {
  color: #ffc107;
  text-decoration: none;
  transition: color 0.3s;
}

.creditLink:hover {
  color: #ff7f50;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
