.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
  font-family: Arial, sans-serif;
}

.heading {
  color: #343a40;
  margin-bottom: 20px;
  font-size: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input {
  width: 300px;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ced4da;
  outline: none;
}

.button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.shortUrlContainer {
  margin-top: 20px;
}

.shortUrl {
  color: #007bff;
  text-decoration: none;
}

.error {
  color: red;
  margin-top: 10px;
}
